import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Banner, Button, Detail, Form, Grid, H3, InputText, InputPassword, Radio } from "@maggioli-design-system/react";
import * as PrivacyService from "../services/Privacy";
import * as UserService from "../services/UserServices";
import * as Macros from "../utils/Macros";
import * as Validator from "../utils/Validator";
import { IHandleLoader } from "../interfaces/IHandleLoader";
import { IField, IPrivacy } from "../interfaces/IPrivacy";
import { IForm } from "../interfaces/IForm";
import DOMPurify from "dompurify";

interface ISubmit {
    text: string;
    status: string;
}

interface Props {
    handleLoader: IHandleLoader;
}

const INIT_TEXT: string = "";
const INIT_STAT: string = "";

function sanitizeHTML(html) {
    const sanitizedHTML = DOMPurify.sanitize(html);
    return { __html: sanitizedHTML };
}

export default function Register({ handleLoader }: Props): ReactElement {
    const [form, setForm] = useState<IForm>(null);
    const [commerciale, setCommerciale] = useState<string>(null);
    const [submit, setSubmit] = useState<ISubmit>({ text: INIT_TEXT, status: INIT_STAT });

    useEffect(() => {
        getForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getForm = async () => {
        handleLoader.setLoading(true);
        const res = await PrivacyService.getForms(Macros.privacyToken);
        const data = await res.json();
        handleLoader.setLoading(false);
        if (data.form.length > 0) {
            setForm(data.form.filter((elem: IForm) => elem.nome === "registrazione").pop());
        }
    };

    const privacyHandler = (value: string, privacyName: string) => {
        switch (privacyName) {
            case "commerciale":
                setCommerciale(value);
                break;
            default:
                break;
        }
    };

    const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (commerciale === null) {
            alert("Tutti i campi sono obbligatori.");
            return;
        }

        const resIP = await PrivacyService.getMyIP();
        if (!resIP.ok) {
            setSubmit({ text: "Errore nel recupero dell'IP", status: "error" });
            return;
        }
        const dataIP = await resIP.json();

        const formData = new FormData(e.target);
        const data = {};
        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }
        data["token"] = Macros.privacyToken;
        data["ip"] = dataIP.ip;

        if (!Validator.passwordValidation(data["password"], data["password2"])) {
            alert("La password è obbligatoria e deve essere lunga almeno 6 caratteri. Le password devono coincidere.");
            return;
        }
        if (!Validator.emailValidation(data["email"])) {
            alert("Email non valida.");
            return;
        }

        const resEdit = await UserService.newUser(data["nome"], data["cognome"], data["email"], data["password"], data["password2"]);
        const dataEdit = await resEdit.json();
        if (!dataEdit.status) {
            setSubmit({ text: dataEdit.message, status: "error" });
            window.setTimeout(() => setSubmit({ text: INIT_TEXT, status: INIT_STAT }), 2000);
            return;
        }

        const payload = {
            email: formData.get("email"), // email amministrazione
            // from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Approfondimenti Maggiolicloud", //nome sito
            // replyTo: formData.get("email"), //email inserita dall'utente
            subject: "Benvenuto in Approfondimenti Maggioli",
            description: `<p>Salve ${formData.get("nome")} ${formData.get("cognome")}, </p><p>
                        Maggioli ti dà il benvenuto al nostro portale di Approfondimenti.<br>
                        All'interno di approfondimenti.maggiolicloud.it potrai usufruire in libertà
                        dell'ampio database di approfondimenti realizzati appositamente per Te.</p>
                        <p>Per abilitare il tuo account è necessario cliccare sul link sottostante:<br>
                        ${Macros.adminURL}4ct!v4t3/${dataEdit.data}</p>
                        <p>Buona navigazione,<br>
                        Lo staff di Maggioli Editore.</p>`,
            // params: {
            //     ...Macros.emailParams,
            // },
        };

        handleLoader.setLoading(true);
        const resPrivacy = await PrivacyService.saveConsentInPrivacy(data);
        if (!resPrivacy.ok) {
            return;
        }
        const resMail = await PrivacyService.sendEmail(payload);
        handleLoader.setLoading(false);
        if (resMail.ok) {
            setSubmit({ text: "Account creato con successo!", status: "success" });
            sessionStorage.setItem("thankYouType", "reg");
            window.setTimeout(() => window.location.replace("/thank-you"), 2000);
        }
    };

    const privacyFields = (): JSX.Element[] => {
        return form.privacy.map((elem: IPrivacy, index: number) => (
            <div key={index}>
                <Detail className="privacy-consent" htmlTag={"div"}>
                    <p dangerouslySetInnerHTML={sanitizeHTML(elem.description)} />
                    {elem.fields.map((item: IField, i: number) => (
                        <Radio
                            key={i}
                            name={item.name}
                            value={item.options.value}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => privacyHandler(e.target.value, item.name)}
                        >
                            {item.options.text}
                        </Radio>
                    ))}
                </Detail>
            </div>
        ));
    };

    return (
        <div className="padding-normal background-color-adjust-tone-19">
            <H3 className="text-center vertical-padding-xxsmall">Registrazione</H3>
            <Grid columns="3">
                <div></div>
                {form !== null && (
                    <Form
                        onSubmit={(e: ChangeEvent<HTMLFormElement>) => submitForm(e)}
                        className="background-color-adjust-tone-17 padding-small border-radius-small"
                    >
                        {form.fields
                            .filter((elem) => elem.type !== "hidden")
                            .map((elem, index) =>
                                elem.type !== "password" ? (
                                    <InputText key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />
                                ) : (
                                    <InputPassword key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />
                                ),
                            )}

                        {form.fields
                            .filter((elem) => elem.type === "hidden")
                            .map((elem, index) => (
                                <input key={index} name={elem.name} value={elem.options.value} type={elem.type} />
                            ))}
                        <input name="trattamento" value="1" type="hidden" />
                        <input name="servizio" value={form.servizio} type="hidden" />
                        <Detail htmlTag={"div"}>
                            Cliccando su "Invia" dichiari di aver letto la
                            <a href="https://privacy.maggiolicloud.it/privacy/approfondimentimaggiolicloudit"> privacy policy.</a>
                        </Detail>

                        {privacyFields()}
                        <div>
                            <Button type="submit">Invia</Button>
                        </div>
                        {submit.status !== "" && (
                            <Banner status={submit.status} className="border-radius-small">
                                <Detail>{submit.text}</Detail>
                            </Banner>
                        )}
                    </Form>
                )}
            </Grid>
        </div>
    );
}
